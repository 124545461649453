import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs'; // Import Tabs
import Tab from '@mui/material/Tab';
import Drawer from '@mui/material/Drawer';
import axios from 'axios'; // Import Axios for making HTTP requests
import { useTheme, useMediaQuery } from '@mui/material';


function ItinerarySidebar() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [savedItineraries, setSavedItineraries] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0); 
  const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const[drawerOpen, setDrawerOpen] = useState(false)

  const handleItineraryClick = (itinerary) => {
    setSelectedItinerary(itinerary);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    axios
      .get(`${baseUrl}/api/itineraries/`, {
        withCredentials: true, 
        headers: {
          'Content-Type': 'application/json',
        },
      }) 
      .then((response) => {
        setSavedItineraries(response.data);
      })
      .catch((error) => {
      });
  }, []);

  return (
    <div >
      <Drawer 
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          style: {
            width: isSmallScreen ? '75%' : '400px', // Adjust the width based on the screen size
          },
        }}
      >
        <List>
        {savedItineraries.map((itinerary) => (
          <ListItem
            button
            key={itinerary.id}
            onClick={() => handleItineraryClick(itinerary)}
          >
            <ListItemText primary={itinerary.title} />
          </ListItem>
        ))}
        </List>
      </Drawer >
      <Button
        variant="outlined"
        onClick={() => setDrawerOpen(true)}
        style={{ marginLeft: '1rem', marginTop:'1rem', marginRight:'1rem' }}
        
      >
        Open Itineraries
      </Button>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        {selectedItinerary && (
          <>
            <DialogTitle>{selectedItinerary.title}</DialogTitle>
            <DialogContent>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto">
                <Tab label="Detailed Itinerary" />
                <Tab label="Local Insights" />
                <Tab label="Accomodation Recommendations" />
                <Tab label="Transportation Options" />
                <Tab label="Restaurant Recommendations" />
                <Tab label="Budget Management" />
                <Tab label="Weather Infomation" />
                <Tab label="Safety and Health Tips" />
                {/* Add more tabs as needed */}
              </Tabs>
              <div>
                {selectedTab === 0 && (
                  <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    {selectedItinerary.detailed_itinerary}
                  </Typography>
                )}
                {selectedTab === 1 && (
                  <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    {selectedItinerary.local_insights}
                    {/* Display money saving tips here */}
                  </Typography>
                )}
                {selectedTab === 2 && (
                  <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    {selectedItinerary.accomodation_recommendations}
                    {/* Display money saving tips here */}
                  </Typography>
                )}
                {selectedTab === 3 && (
                  <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    {selectedItinerary.transportation_options}
                    {/* Display money saving tips here */}
                  </Typography>
                )}
                {selectedTab === 4 && (
                  <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    {selectedItinerary.restaurant_recommendations}
                    {/* Display money saving tips here */}
                  </Typography>
                )}
                {selectedTab === 5 && (
                  <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    {selectedItinerary.budget_management}
                    {/* Display money saving tips here */}
                  </Typography>
                )}
                {selectedTab === 6 && (
                  <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    {selectedItinerary.weather_infomation}
                    {/* Display money saving tips here */}
                  </Typography>
                )}
                {selectedTab === 7 && (
                  <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                    {selectedItinerary.safety_and_health_tips}
                    {/* Display money saving tips here */}
                  </Typography>
                )}
                {/* Add content for other tabs */}
              </div>
            </DialogContent>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
          </>
        )}
      </Dialog>
    </div>
  );
}

export default ItinerarySidebar;