import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Typography } from '@mui/material';

const Hero = () => {
  // Check if the user is logged in by looking for the auth token in localStorage
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // Function to check login status
    const checkLoginStatus = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseUrl}/api/auth/token/verify/`, { // Replace with your verification URL
          method: 'GET',
          credentials: 'include', // Include cookies with the request
        });

        if (response.ok) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
      }
    };

    checkLoginStatus();
  }, []);

  // Determine the navigation path based on the user's logged-in status
  const navigationPath = isLoggedIn ? "/itinerary" : "/signup";

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${process.env.PUBLIC_URL}/town_hero.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '110vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
      }}
    >
      <Container maxWidth="sm" style={{ textAlign: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Embark on Unforgettable Journeys
        </Typography>
        <Typography variant="h5" component="p" paragraph>
          Your personalized, high-quality itineraries crafted in minutes. Start exploring now!
        </Typography>
        <Link to={navigationPath}>
          <Button variant="contained" color="primary">
            {isLoggedIn ? "Start Your Dream Journey" : "Start Your Dream Journey"}
          </Button>
        </Link>
      </Container>
    </div>
  );
};

export default Hero;
