import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ whiteSpace: 'pre-line' }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function NewItineraryDisplay({ itinerary }) {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom >
        {itinerary.title}
      </Typography>
      <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        <Tab label="Detailed Itinerary" />
        <Tab label="Local Insights" />
        <Tab label="Accommodation Recommendations" />
        <Tab label="Transportation Options" />
        <Tab label="Restaurant Recommendations" />
        <Tab label="Budget Management" />
        <Tab label="Weather Information" />
        <Tab label="Safety and Health Tips" />
        {/* Add more tabs as needed */}
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        {itinerary.detailed_itinerary}
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {itinerary.local_insights}
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        {itinerary.accomodation_recommendations}
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        {itinerary.transportation_options}
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        {itinerary.restaurant_recommendations}
      </TabPanel>
      <TabPanel value={selectedTab} index={5}>
        {itinerary.budget_management}
      </TabPanel>
      <TabPanel value={selectedTab} index={6}>
        {itinerary.weather_infomation}
      </TabPanel>
      <TabPanel value={selectedTab} index={7}>
        {itinerary.safety_and_health_tips}
      </TabPanel>

      {/* Repeat for other tabs */}
    </Container>
  );
}

export default NewItineraryDisplay;
