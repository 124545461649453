// src/App.js
import React from 'react';
import LoggedInHeader from '../components/LoggedInHeader';
import ProfileComponent from './ProfileComponent';



function ProfilePage() {
  return (
    <div className="ProfilePage">
        <LoggedInHeader/>
        <ProfileComponent/>
    </div>
  )
}

export default ProfilePage;