import React, { useState, useEffect } from 'react';
import InputForm from './InputForm';
import LoggedInHeader from '../components/LoggedInHeader';
import LoggedOutHeader from '../components/LoggedOutHeder';
import NewItineraryDisplay from './newItineraryDisplay';
import NewItinerarySidebar from './newitinerarySidebar';
import TitleDescription from './TitleDescription';

const NewItineraryPlanner = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentView, setCurrentView] = useState('create'); // 'create' or 'itinerary'
  const [selectedItinerary, setSelectedItinerary] = useState(null);

  useEffect(() => {
    // Function to check login status
    const checkLoginStatus = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseUrl}/api/auth/token/verify/`, { // Replace with your verification URL
          method: 'GET',
          credentials: 'include', // Include cookies with the request
        });

        if (response.ok) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
      }
    };

    checkLoginStatus();
  }, []);


  // Function to handle selecting an itinerary from the sidebar
  const handleSelectItinerary = (itinerary) => {
    setSelectedItinerary(itinerary);
    setCurrentView('itinerary');
  };

  // Function to handle clicking the "Create New" button
  const handleCreateNew = () => {
    setCurrentView('create');
  };

  return (
    <div>
      {isLoggedIn ? <LoggedInHeader /> : <LoggedOutHeader />}
      <NewItinerarySidebar onSelectItinerary={handleSelectItinerary} onCreateNew={handleCreateNew} />
      <div style={{ flex: '1', padding: '1rem' }}>
        {currentView === 'create' && (
        <>
        <TitleDescription/>
        <InputForm />
        </>)}
        {currentView === 'itinerary' && selectedItinerary && <NewItineraryDisplay itinerary={selectedItinerary} />}
      </div>
    </div>
  );
};

export default NewItineraryPlanner;