import React, { useState } from 'react';
import { Container, Typography, Grid, TextField, Button, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

function ContactSection() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    axios.post(`${baseUrl}/api/contact/`, {
      name,
      email,
      message
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      setSuccessOpen(true)
      // Handle success (clear form, show success message)
      setName('');
      setEmail('');
      setMessage('');
    })
    .catch(error => {
      // Handle error
      if (error.response && error.response.data) {
        // Extract specific error message from response if available
        setErrorMessage(Object.values(error.response.data).join(' '));
      } else {
        // Generic error message for other errors
        setErrorMessage('Failed to send message. Please try again later.');
      }
      setErrorOpen(true); // Open error Snackbar
    });
  };
  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', paddingTop: '7rem', paddingBottom: '5rem' }}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Have questions or need assistance? Feel free to reach out to us.
      </Typography>
      <Grid container spacing={2} justifyContent="center" style={{ marginTop: '2rem' }}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Your Name"
            variant="outlined"
            fullWidth
            required
            value={name}
            onChange={handleNameChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            required
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            required
            value={message}
            onChange={handleMessageChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Send Message
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
          Message sent successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ContactSection;
