import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios'; // Import Axios for making HTTP requests
import PayPalButton from '../components/PayPalButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Link, Stepper, Step, StepLabel, Button, Box } from '@mui/material';

function InputForm() {
  const steps = ['Select Title and Expert', 'Who', 'Where', 'What', 'When', 'Budget', 'Additional Information & Payment'];
  const [activeStep, setActiveStep] = useState(0);
  const [errorOpen, setErrorOpen] = useState(false);
const [errorMessage, setErrorMessage] = useState('');

const handleErrorClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorOpen(false);
};
  
  const [who, setWho] = useState('');
  const [where, setWhere] = useState('');
  const [what, setWhat] = useState('');
  const [when, setWhen] = useState('');
  const [budget, setBudget] = useState('');
  const [more, setMore] = useState('');
  const [title, setTitle] = useState('');
  const [selectedExpert, setSelectedExpert] = useState('');
  const [experts, setExperts] = useState([]); // Store the list of experts from the backend
  const [showCreationMessage, setShowCreationMessage] = useState(false);

  const createItinerary = async (title, who, where, what, when, budget, more, selectedExpert) => {
    setShowCreationMessage(true); // Show the message
    const newItinerary = {
      title: title,
      who: who,
      where: where,
      what: what,
      when:when,
      budget:budget,
      more:more,
      expert: selectedExpert,
    };
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    try{
     await axios.post(`${baseUrl}/api/itineraries/`, newItinerary, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
  setTimeout(() => {
    setShowCreationMessage(false);
  }, 25000);
} catch (error) {
  setErrorMessage('An error occurred while creating the itinerary.');
  setErrorOpen(true);
}
};
  const handleWhoChange = (event) => {
    setWho(event.target.value);
  };
  const handleWhereChange = (event) => {
    setWhere(event.target.value);
  };
  const handleWhatChange = (event) => {
    setWhat(event.target.value);
  };
  const handleWhenChange = (event) => {
    setWhen(event.target.value);
  };
  const handleBudgetChange = (event) => {
    setBudget(event.target.value);
  };
  const handleMoreChange = (event) => {
    setMore(event.target.value);
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleExpertChange = (event) => {
    setSelectedExpert(event.target.value);
  };
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseUrl}/api/auth/token/verify/`, {
          method: 'GET',
          credentials: 'include', 
        });
        if (response.ok) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
      }
    };
    checkLoginStatus();
  }, []);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    axios
      .get(`${baseUrl}/api/experts/`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }) 
      .then((response) => {
        setExperts(response.data);
      })
      .catch((error) => {
      });
  }, []); 

  return (
    <div style={{marginTop:'2rem'}}>
    <Container maxWidth="sm" style={{ textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Plan Your Itinerary
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form >
      {activeStep === 0 && (
      <>
        <TextField
          fullWidth
          label="Title"
          variant="outlined"
          value={title}
          onChange={handleTitleChange}
          margin="normal"
        />
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Select an Expert</InputLabel>
          <Select
            value={selectedExpert}
            onChange={handleExpertChange}
            label="Select an Expert"
          >
            {experts.map((expert) => (
              <MenuItem key={expert.id} value={expert.id}>
                {expert.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Alert severity='info'>The title you choose is not used in the itinerary generation - it is only so you can refer back to the itinerary later.<br/>The expert you choose sets the context for our language model - if unsure choose general.</Alert>
        </>
        )}
        {activeStep === 1 && (
      <>
        <TextField
          fullWidth
          label="Who is this itinerary for?"
          multiline
          maxRows={4}
          variant="outlined"
          value={who}
          onChange={handleWhoChange}
          margin="normal"
        />
        <Alert severity='info'>Are you planning a solo trip or group trip? Are you planning a trip for someone else? Feel free to be descriptive. If you are unsure type in "I'm not sure yet".</Alert>
        </>
        )}
        {activeStep === 2 && (
      <>
        <TextField
          fullWidth
          label="Where do you want to go?"
          multiline
          maxRows={4}
          variant="outlined"
          value={where}
          onChange={handleWhereChange}
          margin="normal"
        />
        <Alert severity='info'>Include all the places you intend to go - if you have specifics or just general areas thats okay!"</Alert>

        </>
        )}
        {activeStep === 3 && (
      <>
        <TextField
          fullWidth
          label="What do you want to do?"
          multiline
          maxRows={4}
          variant="outlined"
          value={what}
          onChange={handleWhatChange}
          margin="normal"
        />
        <Alert severity='info'>Include all the things you want to do or see - the more detail the better.</Alert>

        </>
        )}
        {activeStep === 4 && (
      <>
        <TextField
          fullWidth
          label="When do you want to go?"
          multiline
          maxRows={4}
          variant="outlined"
          value={when}
          onChange={handleWhenChange}
          margin="normal"
        />
        <Alert severity='info'>You can be as vague as you want and let us recommend the best time or give specific dates. Include the length of your trip if possible</Alert>
        </>
        )}
        {activeStep === 5 && (
      <>
        <TextField
          fullWidth
          label="What is your budget?"
          multiline
          maxRows={4}
          variant="outlined"
          value={budget}
          onChange={handleBudgetChange}
          margin="normal"
        />
        <Alert severity='info'>Describe your budget. You can use numbers or words.<br/>Possible examples: "my budget is £1500", "Luxury"</Alert>

        </>
        )}
        {activeStep === 6 && (
      <>
        <TextField
          fullWidth
          label="Would you like to tell us anything else?"
          multiline
          maxRows={4}
          variant="outlined"
          value={more}
          onChange={handleMoreChange}
          margin="normal"
        />
        <Alert severity='info'>Do you have anything else we should consider or include, special requirements, preferences etc.</Alert>

        
        {isLoggedIn ? <PayPalButton
        createItinerary={createItinerary}
        title={title}
        who={who}
        where={where}
        what={what}
        when={when}
        budget={budget}
        more={more}
        selectedExpert={selectedExpert}/> : <Link href="https://www.myjourneygenius.com/signin">Please Login</Link>}
      </>)}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
  <Button
    color="inherit"
    disabled={activeStep === 0}
    onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
  >
    Back
  </Button>
  <Box sx={{ flex: '1 1 auto' }} />
  {activeStep < steps.length - 1 && (
    <Button onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}>
      Next
    </Button>
  )}
</Box>
      </form>
      {showCreationMessage && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <Alert severity='success'>
            <Typography variant="body1" style={{ marginLeft: '1rem' }}>
            Your itinerary is being created in stages. The full process may take up to 10 minutes. Please visit the "Open Itineraries" tab to view updates.
            You may need to wait a few minutes for each part and refresh the page to see new data.            
            </Typography>
            </Alert>
          </div>
        )}    </Container>
    <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose}>
  <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
    {errorMessage}
  </Alert>
</Snackbar>

    </div>
  );
}

export default InputForm;