// ProfilePage.js
import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import axios from 'axios';

const ProfileComponent = () => {
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    axios
      .get(`${baseUrl}/auth/users/me/`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setUserDetails(response.data);
      })
      .catch((error) => {
      });
  }, []);

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        My Profile
      </Typography>
      {userDetails && (
        <div>
          <Typography variant="h6">Username: {userDetails.username}</Typography>
          <Typography variant="h6">Email: {userDetails.email}</Typography>

          {/* Add more user details as needed */}
        </div>
      )}
    </Container>
  );
};

export default ProfileComponent;
