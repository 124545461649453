import React from 'react';
import { Container, Typography, Grid, Card, CardContent } from '@mui/material';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ListAltIcon from '@mui/icons-material/ListAlt';
import GradeIcon from '@mui/icons-material/Grade';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import TouchAppIcon from '@mui/icons-material/TouchApp';

const Features = () => {
  const features = [
    {
      title: 'Powered by AI',
      description: 'Leverage cutting-edge AI to craft your unique travel plans.',
      Icon: AutoGraphIcon,
    },
    {
      title: 'Fully Personalised',
      description: 'Every itinerary is tailored to your individual preferences.',
      Icon: PersonOutlineIcon,
    },
    {
      title: 'Comprehensive',
      description: 'From flights to hotels, get every detail you need.',
      Icon: ListAltIcon,
    },
    {
      title: 'High Quality',
      description: 'Expect nothing less than exceptional travel experiences.',
      Icon: GradeIcon,
    },
    {
      title: 'Speedy',
      description: 'Receive your custom travel guide within minutes.',
      Icon: FlashOnIcon,
    },
    {
      title: 'Easy to Use',
      description: 'User-friendly interface for hassle-free planning.',
      Icon: TouchAppIcon,
    },
  ];

  return (
    <Container maxWidth="md" style={{ paddingTop: '5rem' }}>
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center', marginBottom: '2rem' }}>
        Features
      </Typography>
      <Grid container spacing={5}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card raised style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <feature.Icon style={{ fontSize: 40, marginBottom: '1rem' }} />
                <Typography variant="h6">{feature.title}</Typography>
                <Typography variant="body2">{feature.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Features;

