import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function TitleDescription() {
  return (
    <Container maxWidth="md" style={{ textAlign: 'center', marginBottom: '1rem' }}>
      <Typography variant="h4" gutterBottom>
        Welcome to the Itinerary Planning Tool
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
        Plan your dream trip with personalized, expert advice. Our tool helps you create a tailored travel itinerary that fits your preferences and needs. Only $0.99.
      </Typography>
      <Typography variant="body2" sx={{ marginTop: '1rem', textAlign: 'left', maxWidth: '75%', margin: 'auto' }}>
        Tips for a Great Itinerary:
      </Typography>
      <ul style={{ textAlign: 'left', margin: '1rem auto', maxWidth: '75%' }}>
        <li>Be as specific as possible in your description to get the most tailored advice.</li>
        <li>Include any must-visit places or experiences you're interested in.</li>
        <li>Don't forget to mention any constraints or special requirements you have.</li>
      </ul>
      <p>Warning: if you enter jibberish or something our language model doesn't understand you may receive a random itinerary or nothing at all.</p>

    </Container>
  );
}

export default TitleDescription;


