// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles'; // Import ThemeProvider
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import ItineraryPlanner from './itinerary_page/ItineraryPlanner';
import HomePage from './Home/HomePage';
import ProfilePage from './profile/ProfilePage';
import theme from './theme'; // Import your custom theme
import NewItineraryPlanner from './itinerary_page/newItineraryPlanner';

function App() {
  return (
    <ThemeProvider theme={theme}> {/* Wrap your app with ThemeProvider */}
      <div className="App">
        <Router>
          <Routes>
            <Route path="" element={<HomePage />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/itinerary" element={<NewItineraryPlanner />} />
            <Route path="/profile" element={<ProfilePage />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;

