import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography, Container } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PaymentIcon from '@mui/icons-material/Payment';
import AssignmentIcon from '@mui/icons-material/Assignment';

const steps = [
  {
    label: 'Create an account or login',
    description: 'Sign up to access personalized features or log in to your existing account.',
    Icon: AccountCircleIcon,
  },
  {
    label: 'Answer a few questions describing your dream trip',
    description: 'Describe your dream holiday, and choose an expert for personalized suggestions.',
    Icon: AssignmentIcon,
  },
  {
    label: 'Pay and receive your itinerary and guide',
    description: 'Complete the payment process and get your custom travel itinerary and guide in minutes.',
    Icon: PaymentIcon,
  },
];

export default function HowItWorks() {
  return (
    <Container maxWidth="md" style={{ paddingTop: '10rem', paddingBottom: '7rem' }}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h4" gutterBottom style={{ textAlign: 'center', marginBottom: '4rem' }}>
          How It Works
        </Typography>
        <Stepper activeStep={-1} alternativeLabel>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel
                icon={<step.Icon style={{ fontSize: '2rem' }} />} // Adjust fontSize here
                optional={<Typography variant="caption">{step.description}</Typography>}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Container>
  );
}

