import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography style={{ whiteSpace: 'pre-line' }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function NewItineraryDisplay() {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom >
        Example: Kyoto, Japan
      </Typography>
      <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        <Tab label="Detailed Itinerary" />
        <Tab label="Local Insights" />
        <Tab label="Accommodation Recommendations" />
        <Tab label="Transportation Options" />
        <Tab label="Restaurant Recommendations" />
        <Tab label="Budget Management" />
        <Tab label="Weather Information" />
        <Tab label="Safety and Health Tips" />
        {/* Add more tabs as needed */}
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
      Dear User,
      <br/>
      <br/>Thank you for reaching out to MyjourneyGenius for assistance with planning your cultural exploration trip to Kyoto, Japan. I'm delighted to provide you with a high-quality and in-depth travel itinerary that encompasses the rich cultural heritage, beautiful temples, and natural scenery that Kyoto has to offer.
      <br/>
      <br/>Duration: 5 days/4 nights
      <br/>
      <br/>Day 1 - Arrival and City Orientation:
      <br/>- Upon arrival, settle into your mid-range accommodation in Kyoto.
      <br/>- Start your exploration by visiting the iconic Kiyomizu-dera Temple, known for its stunning wooden terrace and panoramic views of the city.
      <br/>- Head to Gion district in the evening, famous for its traditional wooden machiya houses and geisha culture. Stroll through Hanamikoji Street, where you might catch a glimpse of geishas and enjoy a delicious Kaiseki dinner at a traditional restaurant.
      <br/>
      <br/>Day 2 - Temples and Cultural Heritage:
      <br/>- Begin your day at Fushimi Inari Taisha, one of Kyoto's most important Shinto shrines. Explore the thousands of vibrant red torii gates that wind their way up the sacred Mount Inari.
<br/>      - Visit Kinkaku-ji (Golden Pavilion), a UNESCO World Heritage Site. Admire the reflection of the golden pavilion in the tranquil pond and stroll through the beautifully manicured gardens.
<br/>- Continue your temple tour by visiting Ryoan-ji Temple, known for its Zen rock garden, and Kōtō-in, a sub-temple with magnificent gardens and historic buildings.
<br/>
<br/>Day 3 - Arashiyama and Natural Beauty:
<br/>- Head to Arashiyama, located on the outskirts of Kyoto, to soak in the natural beauty. Start with a visit to the famous bamboo grove, where towering bamboo stalks create a serene atmosphere.
<br/>- Explore Tenryu-ji Temple, a UNESCO World Heritage Site renowned for its magnificent landscape gardens.
<br/>- Take a scenic train ride to Saga-Toriimoto Preserved Street, a charming preserved town with traditional houses and shops.
<br/>- Finally, end the day with a relaxing boat ride along the Hozu River, enjoying the picturesque landscapes.
<br/>
<br/>Day 4 - Nijo Castle and Tea Ceremony:
<br/>- Begin your day with a visit to Nijo Castle, a UNESCO World Heritage Site, known for its lavish interiors and beautifully landscaped gardens.
<br/>- Explore the Sento Imperial Palace Gardens, which were once the residence of retired emperors and offer a glimpse into Japan's imperial past.
<br/>- Experience a traditional Japanese tea ceremony at a tea house in Uji, the birthplace of matcha tea. Learn about the meticulous process of tea preparation and enjoy a serene and peaceful atmosphere.
<br/>
<br/>Day 5 - Philosopher's Path and Departure:
<br/>- Start your day by walking along the Philosopher's Path, a tranquil canal path lined with hundreds of cherry trees that bloom in spring. Enjoy the serene atmosphere and visit smaller temples and shrines along the way.
<br/>- Before departing Kyoto, indulge in some souvenir shopping in the charming district of Higashiyama, known for its traditional crafts and tea houses.
<br/>- Depart from Kyoto with wonderful memories of your cultural exploration trip.
<br/>
<br/>This itinerary gives you a comprehensive experience of Kyoto's cultural heritage, beautiful temples, and natural scenery while keeping in mind your mid-range budget. Feel free to make any adjustments according to your preferences and interests. Should you require any further information or assistance, please do not hesitate to contact me.
<br/>
<br/>Enjoy your journey!
<br/>
<br/>Best regards,
<br/>
<br/>MyjourneyGenius
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
     Here are some insider tips and local insights to enhance your cultural exploration in Kyoto:
<br/>
<br/>1. Day 1: After visiting Kiyomizu-dera Temple, consider taking a stroll through the adjacent Sannenzaka and Ninenzaka streets. These preserved historic lanes are filled with traditional shops, cafes, and teahouses, offering a glimpse into Kyoto's past.
<br/>
<br/>2. For a unique experience in Gion district, book a Maiko or Geisha makeover and photo shoot session. You'll have the opportunity to dress up in traditional attire and capture stunning photos with authentic maiko or geisha.
<br/>
<br/>3. Day 2: Instead of just visiting Fushimi Inari Taisha during the daytime, plan to visit at sunset or after dark when the crowd thins out. The torii gates illuminated by lanterns create a magical atmosphere.
<br/>
<br/>4. For a tranquil experience near Kinkaku-ji, head to the nearby Daitoku-ji Temple complex. The sub-temples within Daitoku-ji offer serene Zen gardens, charming tea houses, and a chance to experience Zen meditation.
<br/>
<br/>5. Day 3: In Arashiyama, take the scenic path beyond the bamboo grove and hike up to the breathtaking viewpoint of Mt. Arashiyama. It offers panoramic views of the surrounding mountains and the Hozu River.
<br/>
<br/>6. Consider extending your visit to Arashiyama by exploring the lesser-known Otagi Nenbutsu-ji Temple. This hidden gem features over 1,200 unique moss-covered statues, each with its own expression and personality.
<br/>
<br/>7. Day 4: Before visiting Nijo Castle, stop by Nishiki Market, often called "Kyoto's Kitchen." Indulge in an array of traditional street food and sample local Kyoto specialties like yuba (tofu skin), tsukemono (pickled vegetables), and matcha-based treats.
<br/>
<br/>8. Experience a tea ceremony in Uji at a tea house overlooking Uji River. Uji is renowned for its high-quality matcha tea, and participating in a tea ceremony here will allow you to immerse yourself in the tradition and culture of tea.
<br/>
<br/>9. Day 5: Take a detour from the Philosopher's Path to visit Honen-in Temple. Nestled in a quiet residential area, this serene temple offers beautiful moss gardens and often hosts art exhibitions that combine tradition and modernity.
<br/>
<br/>10. While shopping in the Higashiyama district, don't just focus on the main commercial street. Explore the narrow alleys and side streets branching off it to discover hidden boutiques, unique handicrafts, and charming local artisans.
<br/>
<br/>Remember to always be respectful of local customs, such as removing your shoes when entering temples or following proper etiquette during tea ceremonies. Immerse yourself in the rich cultural heritage of Kyoto and savor every moment of your trip.
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
     For a mid-range budget, here are some accommodation options that align with the itinerary:
<br/>
<br/>1. Traditional Ryokan: Experience Japanese hospitality by staying in a traditional ryokan, such as Ryokan Shimizu or Ryokan B&B Kyo No En. These ryokans often provide comfortable tatami rooms, futon beds, and an onsen (hot spring) experience.
<br/>
<br/>2. Boutique Hotels: Kyoto has numerous boutique hotels that offer a blend of modern amenities and Japanese aesthetics. You can consider options like Mume Boutique Hotel or Nazuna Kyoto Nijo-jo. These hotels offer stylish rooms, personalized service, and a unique cultural experience.
<br/>
<br/>3. Guesthouses: Stay in cozy guesthouses like Piece Hostel Kyoto or Kyoto Higashiyamasou. These budget-friendly accommodations provide private or shared rooms, communal areas, and a chance to interact with fellow travelers.
<br/>
<br/>4. Machiya (Traditional Townhouse): Experience traditional Japanese living in a machiya, a renovated Kyoto townhouse. Options like Kyomachiya Stay Waka Higashiyama or Kyo no Ondokoro Marutamachi provide a unique cultural experience with modern amenities.
<br/>
<br/>5. Business Hotels: Kyoto also offers a variety of business hotels like Daiwa Roynet Hotel Kyoto Shijo Karasuma or Hotel Keihan Kyoto Grande. These hotels are reasonably priced, centrally located, and provide all the necessary amenities.
<br/>
<br/>Remember to book your accommodation in advance, especially during peak travel seasons, to ensure availability and secure the best prices.
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
      Day 1:
<br/>- Arrival in Kyoto can be done by flying into Kansai International Airport (KIX). From the airport, you can take the Haruka Express train to Kyoto Station, which takes approximately 75 minutes and costs around 2,850 JPY ($26 USD).
<br/>- Once in Kyoto, you can take a taxi or use public transportation to reach your accommodation. The taxi fare will depend on the distance, but it's advisable to use Kyoto's extensive bus and subway network, which offers day passes for unlimited travel. A one-day bus pass costs 600 JPY ($5.50 USD).
<br/>
<br/>Day 2:
<br/>- Fushimi Inari Taisha is easily accessible by train. Take the JR Nara Line from Kyoto Station to Inari Station, which takes about 5 minutes and costs 140 JPY ($1.30 USD).
<br/>- Kinkaku-ji (Golden Pavilion) is a short bus ride away. Take bus number 101 or 205 from the bus stop near the temple and get off at Kinkakuji-michi bus stop. The bus fare is 230 JPY ($2.10 USD).
<br/>
<br/>Day 3:
<br/>- Arashiyama can be reached by taking the JR Sagano Line or the Hankyu Line from Kyoto Station to Saga-Arashiyama Station, which takes about 15 minutes and costs approximately 240 JPY ($2.20 USD).
<br/>- To explore the bamboo grove, Tenryu-ji Temple, and Saga-Toriimoto Preserved Street, these attractions are within walking distance from each other in Arashiyama.
<br/>- To take a boat ride along the Hozu River, you can walk to the Hozukyo Station and take a scenic ride that lasts around 2 hours. The cost is 4,100 JPY ($37 USD).
<br/>
<br/>Day 4:
<br/>- Nijo Castle is located in central Kyoto and can be reached easily by bus. Take the Kyoto City Bus number 9, 12, 50, or 101 to Nijojo-mae bus stop. The bus fare is 230 JPY ($2.10 USD).
<br/>- Sento Imperial Palace Gardens are within walking distance from Nijo Castle.
<br/>- To experience a tea ceremony in Uji, take the JR Nara Line from Kyoto Station to Uji Station, which takes approximately 20 minutes and costs 240 JPY ($2.20 USD).
<br/>
<br/>Day 5:
<br/>- The Philosopher's Path and Higashiyama district are best explored on foot. They are located in the eastern part of Kyoto and offer picturesque walks.
<br/>- You can depart from Kyoto via the same transportation options used to arrive, such as the Haruka Express train from Kyoto Station to Kansai International Airport.
<br/>
<br/>Estimated travel costs for transportation:
<br/>- Total train costs: approximately 3,540 JPY ($32 USD)
<br/>- Total bus costs: approximately 690 JPY ($6.30 USD)
<br/>- Total taxi costs: depending on usage, estimate around 3,000-5,000 JPY ($27-$46 USD)
<br/>- Boat ride cost: 4,100 JPY ($37 USD)
<br/>- Total transportation costs: approximately 11,330 JPY ($103 USD)
<br/>
<br/>Note: Prices are subject to change and may vary depending on exchange rates. It's recommended to check for any updated fare information closer to your travel date.
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
<br/>      Day 1:
<br/>
<br/>- For a Kaiseki dinner, Yoshikawa Kyoto Kaiseki is highly recommended. Located in the Gion district, it offers traditional multi-course meals using fresh ingredients. (Rating: 4.5/5 on TripAdvisor)
<br/>
<br/>Day 2:
<br/>
<br/>- For lunch near Fushimi Inari Taisha, try Torisei Fushimi Inari. This small restaurant serves delicious yakitori skewers and is located just a few minutes from the shrine. (Rating: 4/5 on TripAdvisor)
<br/>- For dinner, Kinkaku-ji offers a serene dining experience with a stunning view of the Golden Pavilion. They serve a variety of Japanese dishes prepared with local ingredients. (Rating: 4/5 on TripAdvisor)
<br/>
<br/>Day 3:
<br/>
<br/>- Arashiyama offers many dining options. For a quick and tasty lunch, try Arashiyama Yoshimura for their famous tofu dishes. (Rating: 4/5 on TripAdvisor)
<br/>- For dinner, consider Sagano-Yuhi. This traditional restaurant offers a variety of Kyoto-style cuisine alongside beautiful views of the Hozu River. (Rating: 4/5 on TripAdvisor)
<br/>
<br/>Day 4:
<br/>
<br/>- Uji is renowned for its tea. For lunch, visit Nakamura Tokichi Honten Uji. They offer a range of traditional Japanese dishes using Uji matcha, including matcha soba noodles and matcha parfaits. (Rating: 4.5/5 on TripAdvisor)
<br/>- Experience a tea ceremony in Uji at Taihoan, a historic tea house known for its tranquil atmosphere and skilled tea masters. (Rating: 4.5/5 on TripAdvisor)
<br/>
<br/>Day 5:
<br/>
<br/>- For a leisurely lunch along the Philosopher's Path, visit Café Revoir. They offer a range of delicious sandwiches, soups, and desserts in a cozy setting. (Rating: 4/5 on TripAdvisor)
<br/>- Before departing, grab a quick snack from Nakamuraya Café, located in the Higashiyama district. They are famous for their delicious matcha and white bean paste sweets. (Rating: 4/5 on TripAdvisor)
<br/>
<br/>Note: It's always recommended to check availability and make reservations in advance, especially for popular restaurants in Kyoto.
      </TabPanel>
      <TabPanel value={selectedTab} index={5}>
      Based on a mid-range budget, here are estimated costs for the activities and attractions mentioned in the itinerary:
<br/>
<br/>Day 1:
<br/>- Accommodation: Prices can vary, but budget around $60-$80 per night for a mid-range hotel.
<br/>- Kiyomizu-dera Temple: Entrance fee is approximately $4-$7.
<br/>- Gion district: Entrance is free, but expect to spend around $25-$30 per person for a Kaiseki dinner.
<br/>
<br/>Day 2:
<br/>- Fushimi Inari Taisha: Free entrance.
<br/>- Kinkaku-ji: Entrance fee is about $4-$7.
<br/>- Ryoan-ji Temple and Kōtō-in: Combined entrance fee is approximately $6-$10.
<br/>
<br/>Day 3:
<br/>- Arashiyama: Entrance to the bamboo grove is free, but expect to spend $6-$8 for Tenryu-ji Temple.
<br/>- Saga-Toriimoto Preserved Street: Free to explore.
<br/>- Hozu River boat ride: Budget around $15-$20 per person.
<br/>
<br/>Day 4:
<br/>- Nijo Castle: Entrance fee is about $6-$9.
<br/>- Sento Imperial Palace Gardens: Free entrance, but donations are appreciated.
<br/>- Tea ceremony in Uji: Budget around $15-$20 per person.
<br/>
<br/>Day 5:
<br/>- Philosopher's Path: Free walk along the path.
<br/>- Higashiyama district shopping: The cost will vary based on your shopping preferences.
<br/>
<br/>Additional costs:
<br/>- Food and transportation: Budget around $40-$60 per day for meals, depending on your dining preferences, and consider using public transportation, such as buses and trains, for budget-friendly commuting.
<br/>
<br/>Money-saving tips:
<br/>- Consider purchasing a Kyoto City Bus Pass or a Kyoto One-Day Bus Card for unlimited bus rides within the city.
<br/>- Opt for affordable dining options like ramen shops, local street food, or convenience stores for some meals.
<br/>- Look for budget accommodation options, such as guesthouses or hostels, to save on lodging expenses.
<br/>- Take advantage of free attractions and explore the neighborhoods on foot to save on transportation costs.
<br/>
<br/>Please note that these cost estimates are rough and may vary depending on the season, exchange rates, and personal spending habits. It's always wise to research and plan ahead to get accurate prices and find the best deals.
      </TabPanel>
      <TabPanel value={selectedTab} index={6}>
      Here is the relevant weather information for the given itinerary in Kyoto:
<br/>
<br/>Day 1:
<br/>- Kyoto's average temperature in this season is around 10-15°C (50-59°F), so make sure to dress warmly.
<br/>- Expect occasional showers, so it's a good idea to bring an umbrella or raincoat.
<br/>
<br/>Day 2:
<br/>- Similar temperature range applies for this day - 10-15°C (50-59°F).
<br/>- You might encounter scattered showers, so be prepared with appropriate rain gear.
<br/>
<br/>Day 3:
<br/>- The temperature remains in the 10-15°C (50-59°F) range.
<br/>- There might be a mix of cloudy and sunny weather, so it's a good idea to bring layers for changing conditions.
<br/>- Don't forget to pack a light jacket or sweater for cooler moments.
<br/>
<br/>Day 4:
<br/>- The temperature remains around 10-15°C (50-59°F).
<br/>- Similar to the previous days, be prepared for possible showers.
<br/>
<br/>Day 5:
<br/>- The temperature range is still approximately 10-15°C (50-59°F).
<br/>- Again, bring a light jacket or sweater, and have your umbrella or raincoat handy for potential showers.
<br/>
<br/>Overall, during this itinerary, Kyoto has a cool and slightly unpredictable climate. It is advisable to pack layers to accommodate temperature changes throughout the day and be prepared for some rain showers.
      </TabPanel>
      <TabPanel value={selectedTab} index={7}>
      Safety and Health Tips for the Itinerary:
<br/>
<br/>1. Stay hydrated: Carry a water bottle with you at all times and drink plenty of fluids, especially during the hot and humid months.
<br/>
<br/>2. Wear comfortable shoes: As you'll be exploring various temples, gardens, and streets, make sure to wear comfortable and supportive shoes to prevent foot fatigue and injuries.
<br/>
<br/>3. Carry sunscreen and a hat: Protect yourself from the sun's harmful rays by applying sunscreen regularly and wearing a hat or cap to shade your face.
<br/>
<br/>4. Be cautious with street food: If you decide to try street food in Kyoto, opt for stalls that are busy and where the food is prepared fresh. Avoid eating raw or undercooked items to minimize the risk of foodborne illnesses.
<br/>
<br/>5. Be mindful of your belongings: Kyoto is generally safe, but like any popular tourist destination, pickpocketing can occur. Keep your valuables secure, and be aware of your surroundings in crowded areas.
<br/>
<br/>6. Familiarize yourself with emergency procedures: Take note of the emergency contact numbers for local authorities, and familiarize yourself with the location of the nearest hospitals or clinics in case of any medical emergencies.
<br/>
<br/>7. Respect local customs and traditions: When visiting temples and shrines, it's important to understand and follow proper etiquette, such as removing your shoes at specific places and respecting no photography zones.
<br/>
<br/>8. Be mindful of others: Kyoto is a busy city, so be considerate of other visitors, especially in crowded areas. Avoid blocking pathways or taking up excessive space when taking photos or stopping to admire the surroundings.
<br/>
<br/>9. Check for travel advisories: Stay updated on any travel advisories or warnings issued by your home country's government, as well as local authorities in Kyoto, to ensure your safety while traveling.
<br/>
<br/>10. Stay informed about COVID-19 guidelines: Check the latest COVID-19 guidelines and travel restrictions in Kyoto before your trip. Follow recommended protocols such as wearing face masks, practicing social distancing, and using hand sanitizer when necessary.
      </TabPanel>

      {/* Repeat for other tabs */}
    </Container>
  );
}

export default NewItineraryDisplay;