// utils/auth.js

export const logoutUser = () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    // Make a POST request to the backend's logout endpoint
    fetch(`${baseUrl}/api/auth/token/logout/`, { // Replace with your actual logout URL
        method: 'POST',
        credentials: 'include', // Include cookies with the request
    })
    .then(response => {
        if (response.ok) {
            // Redirect the user to the homepage after successful logout
            window.location.href = '/'; // Replace with the actual URL of your homepage
        } else {
            // Handle any errors or unsuccessful logout attempts
        }
    })
    .catch(error => {
        // Handle any exceptions during the logout process
    });
};
