import { React, useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

function Footer() {
  // Check if user is logged in
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // Function to check login status
    const checkLoginStatus = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseUrl}/api/auth/token/verify/`, { // Replace with your verification URL

          method: 'GET',
          credentials: 'include', 
        });

        if (response.ok) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
      }
    };

    checkLoginStatus();
  }, []);

  return (
    <footer>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">About Us</Typography>
            <Typography variant="body2">
            Journey Genius is dedicated to crafting personalized travel experiences. Our innovative approach ensures your travel is unforgettable.{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Quick Links</Typography>
            <ul>
              {isLoggedIn ? (
                <>
                  <li>
                    <Link href="/itinerary">Itineraries</Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link href="/signin">Login</Link>
                  </li>
                  <li>
                    <Link href="/signup">Sign Up</Link>
                  </li>
                </>
              )}
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Connect With Us</Typography>
            <Typography variant="body2">
              Follow us on social media for updates and more.
            </Typography>
            <Link href="https://www.instagram.com/myjourneygenius/">Instagram</Link>
          </Grid>
        </Grid>
        <hr style={{ margin: '2rem 0' }} />
        <Typography variant="body2" color="textSecondary" align="center">
          &copy; {new Date().getFullYear()} My Journey Genius. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;


