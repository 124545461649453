import React, { useState, useRef } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function PayPalButton({ createItinerary, title, who, where, what, when, budget, more, selectedExpert }) {
    const propsRef = useRef();
  propsRef.current = { title, who, where, what, when, budget, more, selectedExpert };
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, 
    "enable-funding": "card",
    "disable-funding": "paylater,venmo",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("info");

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="PayPalButton">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={async () => {
            try {
              const baseUrl = process.env.REACT_APP_API_BASE_URL;
              const response = await axios.post(`${baseUrl}/api/orders/`, {
                
              
                cart: [
                  {
                    id: "1",
                    quantity: "1",
                  },
                ],
              }, {
                withCredentials: true,
              });

              const orderData = response.data;
              if (orderData.id) {
                return orderData.id;
              } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);

                throw new Error(errorMessage);
              }
            } catch (error) {
              setMessage(`Could not initiate PayPal Checkout: ${error.message || error.toString()}`);
              setSeverity('error');
              setOpen(true);
            }
          }}
          onApprove={async (data, actions) => {
            try {
              const baseUrl = process.env.REACT_APP_API_BASE_URL;
              const response = await axios.post(`${baseUrl}/api/orders/${data.orderID}/capture/`,{
                withCredentials: true,
              },);

              const orderData = response.data;

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
              } else if (errorDetail) {
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                const transaction =
                  orderData.purchase_units[0].payments.captures[0];
                  setMessage(`Transaction ${transaction.status}: ${transaction.id}.`);
                  setSeverity('success');
                  setOpen(true);
                  createItinerary(propsRef.current.title, propsRef.current.who, propsRef.current.where, propsRef.current.what, propsRef.current.when, propsRef.current.budget, propsRef.current.more, propsRef.current.selectedExpert);
              }
            } catch (error) {
              setMessage(`Sorry, your transaction could not be processed: ${error.message || error.toString()}`);
              setSeverity('error');
              setOpen(true);
            }
          }}
        />
      </PayPalScriptProvider>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PayPalButton;