import { React, useState, useEffect } from 'react';
import Hero from './Hero';
import Features from './Features';
import LoggedInHeader from '../components/LoggedInHeader'; // Import LoggedInHeader component
import LoggedOutHeader from '../components/LoggedOutHeder'; // Import LoggedOutHeader component
import HowItWorks from './HowItWorks';
import ContactSection from './Contact';
import Footer from './Footer';
import Example from './Example'

function HomePage() {
  // Determine the user's login status based on the presence of the authentication token
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // Function to check login status
    const checkLoginStatus = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseUrl}/api/auth/token/verify/`, { // Replace with your verification URL
          method: 'GET',
          credentials: 'include', // Include cookies with the request
        });

        if (response.ok) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
      }
    };

    checkLoginStatus();
  }, []);

  return (
    <div>
      {/* Conditional rendering of the header based on login status */}
      {isLoggedIn ? <LoggedInHeader /> : <LoggedOutHeader />}

      <Hero />    
      <Features />
      <HowItWorks/> 
      <Example/>
      <ContactSection/>
      <Footer/>


    </div>
  );
}

export default HomePage;

