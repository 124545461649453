import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useTheme, useMediaQuery } from '@mui/material';


function NewItinerarySidebar({ onSelectItinerary, onCreateNew }) {
  const [savedItineraries, setSavedItineraries] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  useEffect(() => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    axios
      .get(`${baseUrl}/api/itineraries/`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setSavedItineraries(response.data);
      })
      .catch((error) => {
        // Handle errors here, such as updating the state to display a message
      });
  }, []);

  return (
    <div>
      <Button variant="outlined"
        style={{ marginLeft: '1rem', marginTop:'1rem', marginRight:'1rem' }} onClick={() => setDrawerOpen(true)}>Open Itineraries</Button>
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} PaperProps={{
          style: {
            width: isSmallScreen ? '75%' : '400px', // Adjust the width based on the screen size
          },
        }}>
      
        <List>
          <Button variant="outlined" onClick={onCreateNew} PaperProps={{
          style: {
            width: isSmallScreen ? '75%' : '400px', // Adjust the width based on the screen size
          },
        }}>
            Create New
          </Button>
          {savedItineraries.map((itinerary) => (
            <ListItem button key={itinerary.id} onClick={() => onSelectItinerary(itinerary)}>
              <ListItemText primary={itinerary.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
}

export default NewItinerarySidebar;
